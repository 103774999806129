import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Delete, Add, Remove } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";

import { useAppContext } from "../../../context";
import { singularize } from "../../../helpers/singularize";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 600,
    width: "100%",
    tableLayout: "fixed",
    "& thead tr th": {
      fontWeight: "bold",
    },
  },
  tableHeader: {
    fontWeight: "bold",
  },
  itemColumn: {
    // backgroundColor: theme.palette.secondary.light,
  },
  qtyColumn: {
    // backgroundColor: "white",
    paddingRight: "2rem",
  },
  unitColumn: {
    // backgroundColor: "purple",
  },
  sumColumn: {
    // backgroundColor: "grey",
  },
  actionColumn: {
    // backgroundColor: "yellow",
  },
}));

function ccyFormat(num) {
  return `${num.toLocaleString("DE-de")}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit, id) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price, id };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

// const rows = [
//   createRow('Paperclips (Box)', 100, 1.15),
//   createRow('Paper (Case)', 10, 45.99),
//   createRow('Waste Basket', 2, 17.99),
// ];

// const invoiceSubtotal = subtotal(rows);

export const ProductsTable = () => {
  const classes = useStyles();
  const { cart, setCart } = useAppContext();

  const [rows, setRows] = useState([]);
  const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);

  // Table Creator
  useEffect(() => {
    if (cart.length > 0) {
      const newRows = [];
      cart.forEach(({ name, category, price, id, qty }) => {
        newRows.push(
          createRow(`${name} ${singularize(category)}`, qty, price, id)
        );
      });
      setRows(newRows);
    }
  }, [cart]);

  // Subtotal calculation
  useEffect(() => {
    if (rows.length > 0) {
      setInvoiceSubtotal(subtotal(rows));
    }
  }, [rows]);

  // Handlers
  const handleAddItem = (index, qty) => {
    const cartCopy = [...cart];
    const idx = cartCopy.findIndex(({ id }) => id === index);
    const itemCopy = cartCopy[idx];

    if (itemCopy.qty === 1 && qty === -1) {
      return;
    }

    itemCopy.qty += qty;
    cartCopy[idx] = itemCopy;
    setCart(cartCopy);
  };

  const handleRemoveItem = (index) => {
    const confirmed = window.confirm("Desea eliminar el producto?");
    if (confirmed) {
      setCart(cart.filter(({ id }) => id !== index));
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.itemColumn}>Item</TableCell>
            <TableCell align="right" className={classes.qtyColumn}>
              Qty.
            </TableCell>
            <TableCell align="right" className={classes.unitColumn}>
              Unit
            </TableCell>
            <TableCell align="right" className={classes.sumColumn}>
              Sum
            </TableCell>
            <TableCell align="right" className={classes.actionColumn}>
              Quitar
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.desc}</TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => {
                    handleAddItem(row.id, -1);
                  }}
                >
                  <Remove />
                </IconButton>
                {row.qty}
                <IconButton
                  onClick={() => {
                    handleAddItem(row.id, +1);
                  }}
                >
                  <Add />
                </IconButton>
              </TableCell>
              <TableCell align="right">{ccyFormat(row.unit)}</TableCell>
              <TableCell align="right">{ccyFormat(row.price)}</TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => {
                    handleRemoveItem(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2}>Subtotal</TableCell>
            <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
