import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ProductsTable } from "./CurrentShoppingCartProductsTable";

import { useAppContext } from "../../../context";

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "capitalize",
  },
  listItem: {
    textTransform: "capitalize",
  },
  emptyCart: {
    margin: 0,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

export const CurrentShoppingCart = () => {
  const classes = useStyles();
  const { cart } = useAppContext();

  return (
    <Container>
      <Typography align="center" className={classes.title} variant="h5">
        Pedido actual
      </Typography>
      {cart.length > 0 ? (
        <ProductsTable />
      ) : (
        <Typography align="center" className={classes.emptyCart} variant="h6">
          No hay productos en esta orden, agrega uno en el paso anterior.
        </Typography>
      )}
    </Container>
  );
};
