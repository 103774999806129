import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Warning } from "@material-ui/icons";

import ProductCard from "./ProductCard";

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: `0px ${theme.spacing(2)}px`,
    overflowY: "auto",
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
  },
  noProductsContainer: {
    width: "100%",
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',

    '& > *:first-child':{
      marginRight: theme.spacing(2),
    },
  },
}));

const ProductsProductsContainer = ({ products, addProductToCartFn }) => {
  const classes = useStyles();
  return (
    <div className={classes.cardsContainer}>
      {products.length > 0 ? (
        products.map(({ category, description, url, id, name, price }) => (
          <ProductCard
            addProductFn={addProductToCartFn}
            category={category}
            description={description}
            key={id}
            id={id}
            name={name}
            price={price}
            url={url}
          />
        ))
      ) : (
        <div className={classes.noProductsContainer}>
          <Warning />
          <Typography align="center" variant="h5">
            <span>No hay productos en esta categoría</span>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ProductsProductsContainer;

ProductsProductsContainer.propTypes = {
  products: PropTypes.array.isRequired,
  addProductToCartFn: PropTypes.func.isRequired,
};
