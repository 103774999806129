import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Container from "@material-ui/core/Container";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import { OrdersStatusCard } from "./OrderCard";
import AddOrderModal from "./AddOrder/AddOrderModal";
import EditOrderModal from "./EditOrder/EditOrderModal";
import DeleteOrderModal from "./DeleteOrder/DeleteOrderModal";
import SearchOrderModal from "./SearchOrder";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(207,232,252, 0.7)",
    maxHeight: 270,
    padding: theme.spacing(2),
  },
  title: {
    minWidth: "100%",
    marginBottom: theme.spacing(2),
  },
  buttonGroup: {
    marginRight: theme.spacing(2),
  },
  cardsContainer: {
    display: "inline-flex",
    overflowX: "auto",
    "& > *": {
      marginRight: theme.spacing(2),
    },
    width: `calc(100% - ${theme.spacing(2)}px - 40px )`,
    paddingBottom: theme.spacing(1),
  },
  card: {
    cursor: "pointer",
    minWidth: 135,
    [theme.breakpoints.up(1130)]: {
      width: `calc(100% / 7 - ${theme.spacing(2)}px)`,
    },
    "&:last-child": {
      marginRight: theme.spacing(0),
    },
    "&:hover": {
      backgroundColor: "#FFA9E7",
    },
    "&:active": {
      backgroundColor: "#E866C3",
    },
  },
}));

export default function HomeOrdersSection({ orders }) {
  const classes = useStyles();
  const modalInitialStates = {
    addOrder: false,
    editOrder: false,
    deleteOrder: false,
    searchOrder: false,
  };
  const [modalStates, setModalStates] = useState(modalInitialStates);
  const [orderStatus, setorderStatus] = useState({});

  const handleButtonsClick = (action) => {
    setModalStates({ ...modalStates, [action]: true });
  };

  const handleCloseModal = () => {
    setModalStates(modalInitialStates);
  };

  useEffect(() => {
    setorderStatus(orders);
  }, [orders]);

  return (
    <>
      <Container maxWidth="xl" className={classes.root}>
        <Typography
          className={classes.title}
          variant="h5"
          component="h4"
          align="center"
        >
          Pedidos
        </Typography>
        <ButtonGroup
          className={classes.buttonGroup}
          orientation="vertical"
          color="primary"
          aria-label="Orders Actions Button Group"
          variant="text"
        >
          <Tooltip title="Crear Pedido" arrow placement="top">
            <Button>
              <AddIcon onClick={() => handleButtonsClick("addOrder")} />
            </Button>
          </Tooltip>
          <Tooltip title="Editar Pedido" arrow placement="top">
            <Button>
              <EditIcon onClick={() => handleButtonsClick("editOrder")} />
            </Button>
          </Tooltip>
          <Tooltip title="Cancelar Pedido" arrow placement="bottom">
            <Button>
              <ClearIcon onClick={() => handleButtonsClick("deleteOrder")} />
            </Button>
          </Tooltip>
          <Tooltip title="Buscar Pedido" arrow placement="bottom">
            <Button>
              <SearchIcon onClick={() => handleButtonsClick("searchOrder")} />
            </Button>
          </Tooltip>
        </ButtonGroup>
        <div id="cards-container" className={classes.cardsContainer}>
          <OrdersStatusCard
            title="En Espera"
            name="waiting"
            link="/orders/waiting"
            orders={orderStatus["pending for restaurant confirmation"]}
            cardClasses={classes.card}
          />
          <OrdersStatusCard
            title="Aceptados"
            name="accepted"
            link="/orders/accepted"
            orders={orderStatus["received by restaurant"]}
            cardClasses={classes.card}
          />
          <OrdersStatusCard
            title="En Curso"
            name="onPreparation"
            link="/orders/on-preparation"
            orders={orderStatus["order on course"]}
            cardClasses={classes.card}
          />
          <OrdersStatusCard
            title="Listos"
            name="ready"
            link="/orders/ready"
            orders={orderStatus["ready to go"]}
            cardClasses={classes.card}
          />
          <OrdersStatusCard
            title="En Reparto"
            name="onDelivery"
            link="/orders/on-delivery"
            orders={orderStatus["order on delivery"]}
            cardClasses={classes.card}
          />
          <OrdersStatusCard
            title="Completos"
            name="completed"
            link="/orders/completed"
            orders={orderStatus["order completed"]}
            cardClasses={classes.card}
          />
          <OrdersStatusCard
            title="Canceladas"
            name="cancelled"
            link="/orders/cancelled"
            orders={orderStatus["cancelled"]}
            cardClasses={classes.card}
          />
        </div>
      </Container>
      <>
        <AddOrderModal
          open={modalStates.addOrder}
          handleClose={handleCloseModal}
        />
        <EditOrderModal
          open={modalStates.editOrder}
          handleClose={handleCloseModal}
        />
        <DeleteOrderModal
          open={modalStates.deleteOrder}
          handleClose={handleCloseModal}
        />
        <SearchOrderModal
          open={modalStates.searchOrder}
          handleClose={handleCloseModal}
        />
      </>
    </>
  );
}

HomeOrdersSection.propTypes = {
  orders: PropTypes.object.isRequired,
};
