import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import db from "../../services/firebase";
import OrdersTable from "./OrdersTable";
import { Button, IconButton, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    float: "right",
    marginTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2),
  },
  noOrders: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  ordersTable: {
    maxWidth: "95%",
    margin: "auto",
  },
  section: {
    padding: theme.spacing(2),
    position: "relative",
  },
  sectionArrowBack: {
    left: theme.spacing(0),
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  sectionArrowNext: {
    position: "absolute",
    right: theme.spacing(0),
    top: "50%",
    transform: "translateY(-50%)",
  },
}));

const OrdersContainer = ({
  actionIcon,
  confirmMessage,
  next,
  nextState,
  previous,
  search,
  title,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [orders, setOrders] = useState([]);

  const fetchOrders = async (mounted) => {
    const ordersArr = [];
    const dbRef = db.collection("orders");
    const result = await dbRef.where("status", "==", search).get();
    result.forEach((doc) => ordersArr.push({ ...doc.data(), id: doc.id }));
    if (mounted) {
      setOrders(ordersArr);
    }
  };

  useEffect(() => {
    let mounted = true;

    fetchOrders(mounted);

    return () => {
      mounted = false;
    };
    //eslint-disable-next-line
  }, [search]);

  const handleUpdateOrdersBatch = async () => {
    const confirmed = window.confirm(`${confirmMessage} todas las ordenes?`);

    try {
      if (confirmed) {
        const batch = db.batch();
        orders.forEach(({ id }) => {
          const docRef = db.collection("orders").doc(id);
          batch.update(docRef, { status: nextState });
        });

        await batch.commit();
        fetchOrders(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className={classes.container}>
      <section className={classes.section}>
        {previous && (
          <IconButton
            className={classes.sectionArrowBack}
            onClick={() => history.replace(previous)}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        <Typography align="center" variant="h5">
          {title}
        </Typography>
        {next && (
          <IconButton
            className={classes.sectionArrowNext}
            onClick={() => history.replace(next)}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </section>
      {orders.length > 0 ? (
        <>
          <OrdersTable
            actionIcon={actionIcon}
            classes={classes.ordersTable}
            confirmMessage={confirmMessage}
            nextState={nextState}
            orders={orders}
            search={search}
          />
          {search !== "order completed" && search !== "order cancelled" && (
            <Button
              className={classes.button}
              color="primary"
              onClick={handleUpdateOrdersBatch}
              variant="outlined"
            >
              Actualizar Todos
            </Button>
          )}
        </>
      ) : (
        <>
          <Typography align="center" className={classes.noOrders} variant="h6">
            No hay órdenes en esta sección
          </Typography>
        </>
      )}
    </div>
  );
};

export default OrdersContainer;
