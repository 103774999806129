import React from "react";
import { useParams } from "react-router-dom";
import OrdersContainer from "../components/OrdersContainer/index";
import StoreIcon from "@material-ui/icons/Store";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import DoneIcon from "@material-ui/icons/Done";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const OrdersWaitingScreen = () => {
  const { orderStatus } = useParams();

  return (
    <>
      {orderStatus === "waiting" && (
        <OrdersContainer
          actionIcon={<StoreIcon />}
          confirmMessage={"Desea aceptar"}
          next="accepted"
          nextState="received by restaurant"
          previous="cancelled"
          search="pending for restaurant confirmation"
          title="Pedidos en espera por confirmación"
        />
      )}
      {orderStatus === "accepted" && (
        <OrdersContainer
          actionIcon={<RestaurantIcon />}
          confirmMessage={"Desea empezar a preparar"}
          next="on-preparation"
          nextState="order on course"
          previous="waiting"
          search="received by restaurant"
          title="Pedidos aceptados"
        />
      )}
      {orderStatus === "on-preparation" && (
        <OrdersContainer
          actionIcon={<DoneIcon />}
          confirmMessage={"Desea terminar de preparar"}
          next="ready"
          nextState="ready to go"
          previous="accepted"
          search="order on course"
          title="Pedidos en curso"
        />
      )}
      {orderStatus === "ready" && (
        <OrdersContainer
          actionIcon={<LocalShippingIcon />}
          confirmMessage={"Desea enviar"}
          next="on-delivery"
          nextState="order on delivery"
          previous="on-preparation"
          search="ready to go"
          title="Pedidos listos para entregar"
        />
      )}
      {orderStatus === "on-delivery" && (
        <OrdersContainer
          actionIcon={<CheckCircleOutlineIcon />}
          confirmMessage={"Desea finalizar"}
          next="completed"
          nextState="order completed"
          previous="ready"
          search="order on delivery"
          title="Pedidos en reparto"
        />
      )}
      {orderStatus === "completed" && (
        <OrdersContainer
          next="cancelled"
          previous="on-delivery"
          search="order completed"
          title="Pedidos finalizados"
        />
      )}
      {orderStatus === "cancelled" && (
        <OrdersContainer
          next="waiting"
          previous="completed"
          search="cancelled"
          title="Pedidos cancelados"
        />
      )}
    </>
  );
};

export default OrdersWaitingScreen;
