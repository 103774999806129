import React from "react";
import { Typography, Container, TextField, MenuItem } from "@material-ui/core";

const reasons = [
  {
    label: "Dirección equivocada",
    value: "wrong-address",
  },
  {
    label: "Cliente no ordenó",
    value: "customer-did-not-order",
  },
  {
    label: "Otro",
    value: "other",
  },
];

export const OrderCancelledForm = ({ fields, handleFieldsChanges }) => {
  return (
    Object.keys(fields).length > 0 && (
      <Container>
        <Typography align="center" variant="h5">
          Motivo de cancelación
        </Typography>
        <TextField
          fullWidth
          label="Motivo"
          margin="normal"
          name="reason"
          onChange={handleFieldsChanges}
          select
          value={fields.reason}
          variant="outlined"
        >
          {reasons.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          label="Descripción"
          margin="normal"
          multiline
          name="notes"
          onChange={handleFieldsChanges}
          placeholder="Describa el motivo de cancelación"
          rows={4}
          value={fields.notes}
          variant="outlined"
        />
      </Container>
    )
  );
};
