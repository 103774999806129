import React from "react";
import { useParams } from "react-router-dom";

import AddProduct from "../components/ProductsSection/HandleProduct";

const ProductScreens = () => {
  const { productOption } = useParams();

  return (
    <>
      {(productOption === "add" || productOption === "agregar") && (
        <AddProduct title="Agregar Nuevo Producto" option={productOption} />
      )}
      {(productOption === "edit" || productOption === "modificar") && (
        <AddProduct title="Editar Producto" option={productOption} />
      )}
      {(productOption === "disable" || productOption === "deshabilitar") && (
        <AddProduct />
      )}
    </>
  );
};

export default ProductScreens;
