import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import StoreIcon from "@material-ui/icons/Store";
import IconButton from "@material-ui/core/IconButton";

import db from "../../services/firebase";
import { timeCalc } from "../../helpers/timeCalc";
import EnhancedTableHead from "./OrdersTableHead";

function createData(id, fullname, address, total, date, delivery) {
  return { id, fullname, address, total, date, delivery };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 280px)",
    maxHeight: "calc(100vh - 280px)",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  actionIcon,
  confirmMessage,
  nextState,
  orders,
  search,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState(
    search === "order completed" ? "desc" : "asc"
  );
  const [orderBy, setOrderBy] = React.useState("date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let mounted = true;
    const ordersArr = [];
    orders.map(({ id, fullname, address, total, timestamp, delivery }) =>
      ordersArr.push(
        createData(id, fullname, address, total, timestamp, delivery)
      )
    );

    if (mounted) {
      setRows(ordersArr);
    }

    return () => {
      mounted = false;
    };
  }, [orders]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleUpdateOrder = async (event, orderID, customerName) => {
    const confirmed = window.confirm(
      `${confirmMessage} el pedido de ${customerName}?`
    );

    if (confirmed) {
      try {
        const docRef = db.collection("orders").doc(orderID);
        await docRef.update({ status: nextState });
        const newRows = rows.filter((item) => item.id !== orderID);
        setRows(newRows);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCompleteOrder = async (event, orderID, customerName) => {
    const confirmed = window.confirm(
      `Desea finalizar el pedido de ${customerName}?`
    );

    if (confirmed) {
      try {
        const docRef = db.collection("orders").doc(orderID);
        await docRef.update({ status: "order completed" });
        const newRows = rows.filter((item) => item.id !== orderID);
        setRows(newRows);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    rows.length > 0 && (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table className={classes.table} size={"medium"} stickyHeader>
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                actionIcon={actionIcon ? true : false}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                        <TableCell
                          align="center"
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align="center">{row.fullname}</TableCell>
                        <TableCell align="center">
                          {row.address || "- - -"}
                        </TableCell>
                        <TableCell align="right">
                          $ {row.total.toLocaleString("de-DE")}
                        </TableCell>
                        <TableCell align="center">
                          {timeCalc(row.date)}
                        </TableCell>
                        {actionIcon && (
                          <TableCell>
                            {!row.delivery && search === "ready to go" ? (
                              <IconButton
                                onTouchEnd={(e) =>
                                  handleCompleteOrder(e, row.id, row.fullname)
                                }
                                onClick={(e) =>
                                  handleCompleteOrder(e, row.id, row.fullname)
                                }
                              >
                                <StoreIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                onTouchEnd={(e) =>
                                  handleUpdateOrder(e, row.id, row.fullname)
                                }
                                onClick={(e) =>
                                  handleUpdateOrder(e, row.id, row.fullname)
                                }
                              >
                                {actionIcon}
                              </IconButton>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    )
  );
}
