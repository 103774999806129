import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddUserIcon from "@material-ui/icons/PersonAdd";

import db from "../services/firebase";
import { useQuery } from "../hooks/useQuery";
import UsersTable from "../components/UsersSection/UsersTable";
import EditUserModal from "../components/UsersSection/EditUserModal";
import Alert from "../components/Alert";

const useStyles = makeStyles((theme) => ({
  button: {
    float: "right",
  },
  centeredButton: {
    left: "50%",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",

    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      "& .MuiButton-startIcon svg": {
        fontSize: theme.spacing(8),
      },
    },
  },
  title: {
    padding: theme.spacing(2),
  },
}));

const UsersScreen = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const role = query.get("role");

  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userSelected, setUserSelected] = useState(null);

  const initialAlertState = { open: false, message: "", severity: "success" };
  const [alertSettings, setAlertSettings] = useState(initialAlertState);

  useEffect(() => {
    const getUsersByRole = async () => {
      if (!role) {
        return history.replace("/");
      }

      try {
        let dbRef = db.collection("users").orderBy("information").limit(10);
        if (role !== "customer") {
          dbRef = db
            .collection("staff")
            .where("role", "==", role)
            .orderBy("fullname")
            .limit(10);
        }

        const users = await dbRef.get();

        // var lastVisible = users.docs[users.docs.length - 1];
        // console.log("last", lastVisible.data());

        // var next = db
        //   .collection("users")
        //   .orderBy("information")
        //   .startAfter(lastVisible)
        //   .limit(3);

        // const newRes = await next.get();

        const usersArr = [];
        users.forEach((user) => {
          // console.log(user.data());
          usersArr.push({ ...user.data(), id: user.id });
        });

        setData(usersArr);
        // newRes.forEach((user) => {
        //   console.log(user.data());
        // });
      } catch (error) {
        console.error(error);
      }
    };

    getUsersByRole();
  }, [role, history]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setUserSelected(null);
  };

  const handleUserSelected = (userInfo) => {
    setUserSelected(userInfo);
    setOpenModal(true);
  };

  const handleAlertClose = (state) => {
    setAlertSettings({ ...alertSettings, open: state });
  };

  const handleAddNewUserModal = () => {
    setUserSelected({
      fullname: "",
      address: "",
      neighborhood: "",
      telephone: "",
      enabled: true,
      email: "",
      newUser: true,
    });
    setOpenModal(true);
  };

  return (
    <Container>
      <Typography
        className={classes.title}
        align="center"
        gutterBottom
        variant="h5"
      >
        {role === "staff" && "Empleados"}
        {role === "delivery" && "Domiciliarios"}
        {role === "customer" && "Clientes"}
      </Typography>

      {data.length > 0 && (
        <UsersTable users={data} onUserSelected={handleUserSelected} />
      )}
      <Button
        className={data.length > 0 ? classes.button : classes.centeredButton}
        color="primary"
        onClick={handleAddNewUserModal}
        startIcon={<AddUserIcon />}
        variant="outlined"
      >
        Agregar Usuario
      </Button>

      {userSelected && (
        <EditUserModal
          open={openModal}
          onClose={handleCloseModal}
          userInfo={userSelected}
          role={role}
          setAlertSettings={setAlertSettings}
        />
      )}
      <Alert
        open={alertSettings.open}
        setOpen={handleAlertClose}
        message={alertSettings.message}
      />
    </Container>
  );
};

export default UsersScreen;
