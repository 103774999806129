import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import { Products } from "./Products";
import { CurrentShoppingCart } from "./CurrentShoppingCart";
import { UserInfo } from "./UserInfo";
import { OrderInfo } from "./OrderInfo";

import { AppContext } from "../../../context";
import { usePersistedState } from "../../../hooks/usePersistedState";
import db from "../../../services/firebase";
import { cartAccum } from "../../../helpers/cartAccum";

const useStyles = makeStyles((theme) => ({
  title: {
    "& .MuiTypography-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  content: {
    minHeight: "50vh",
    padding: theme.spacing(1.5),
    display: "flex",
    flexWrap: "wrap",
    " & > *": {
      flex: "1 1 auto",
    },
  },
  dialog: {
    "& .MuiDialog-container .MuiPaper-root": {
      minHeight: "90%",
      // maxHeight: "90%",
    },
  },
}));

const AddOrderModal = ({ open, handleClose, width }) => {
  const classes = useStyles();
  console.log(width);

  const initialState = {
    fullname: "",
    telephone: "",
    address: "",
    neighborhood: "",
    comments: "",
    delivery: true,
  };
  const [userInfo, setUserInfo] = useState(initialState);
  const [cart, setCart] = usePersistedState("delivreeCart", []);
  const [total, setTotal] = useState(0);
  const [orderStep, setOrderStep] = useState(1);
  const [orderID, setOrderID] = usePersistedState("delivreeOrderID", "");

  const handleAddProduct = (item, qty) => {
    let cartArr = [...cart];
    const productIndex = cart.findIndex((product) => product.id === item.id);
    if (productIndex !== -1) {
      cartArr[productIndex] = {
        ...cartArr[productIndex],
        qty: cartArr[productIndex]["qty"] + qty,
      };
      setCart(cartArr);
    } else {
      setCart([{ ...item, qty }, ...cart]);
    }
  };

  useEffect(() => {
    if (cart.length > 0) {
      let accum = cartAccum(cart);
      setTotal(accum);
    } else {
      setTotal(0);
    }
  }, [cart]);

  const handleNext = () => {
    setOrderStep((step) => step + 1);
  };

  const handleBack = () => {
    setOrderStep((step) => step - 1);
  };

  const handleSendOrder = async () => {
    const orderObject = {
      timestamp: new Date().getTime(),
      cart,
      fullname: userInfo.fullname,
      telephone: userInfo.telephone,
      delivery: userInfo.delivery,
      address: userInfo.address,
      neighborhood: userInfo.neighborhood,
      comments: userInfo.comments,
      total,
      paymentMethod: "cash",
      status: "received by restaurant",
      uid: "guest",
      source: "restaurant",
    };
    // console.log(orderObject);
    const { id } = await db.collection("orders").add(orderObject);
    // console.log(res.id);
    setOrderID(id);
    handleNext();
  };

  const validateForm = () => {
    let passed = true;
    const requiredFields = ["fullname", "telephone", "address", "neighborhood"];
    const ignoredWhenPickUp = ["address", "neighborhood"];

    let pos = 0;
    while (passed && pos <= requiredFields.length - 1) {
      if (userInfo.delivery) {
        if (userInfo[requiredFields[pos]].length === 0) {
          passed = false;
        }
      } else {
        if (
          !ignoredWhenPickUp.includes(requiredFields[pos]) &&
          userInfo[requiredFields[pos]].length === 0
        ) {
          passed = false;
        }
      }
      pos++;
    }
    return passed;
  };

  const handleCloseAddOrderModal = () => {
    setUserInfo(initialState);
    setCart([]);
    setTotal(0);
    setOrderStep(1);
    setOrderID("");
    handleClose();
  };

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      fullScreen={width === "xs"}
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>
        Nuevo Pedido <span>$ {total.toLocaleString("DE-de")}</span>
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
        <AppContext.Provider value={{ userInfo, setUserInfo, cart, setCart }}>
          {orderStep === 1 && (
            <Products addProductToCartFn={handleAddProduct} />
          )}

          {orderStep === 2 && <CurrentShoppingCart />}

          {orderStep === 3 && <UserInfo />}
        </AppContext.Provider>
        {orderStep === 4 && (
          <OrderInfo orderID={orderID} cellphone={userInfo.telephone} />
        )}
      </DialogContent>
      <DialogActions>
        {orderStep > 1 && orderStep !== 4 && (
          <Button color="secondary" onClick={handleBack}>
            Atrás
          </Button>
        )}
        {orderStep < 3 && (
          <Button color="primary" disabled={!cart.length} onClick={handleNext}>
            Siguiente
          </Button>
        )}
        {orderStep === 3 && (
          <Button
            color="primary"
            disabled={!validateForm()}
            onClick={handleSendOrder}
          >
            Enviar Pedido
          </Button>
        )}
        {orderStep === 4 && (
          <Button color="primary" onClick={handleCloseAddOrderModal}>
            Cerrar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withWidth()(AddOrderModal);
