import React from "react";
import PropTypes from "prop-types";
import { Container, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    "& > *": {
      marginBottom: "3rem",
    },
  },
  linkContainer: {
    textDecoration: "none",
  },
}));
export const OrderInfo = ({
  orderID,
  cellphone,
  message = "Orden Finalizada",
}) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography align="center" variant="h3">
        {message}
      </Typography>
      <Typography align="center" variant="h5">
        Id de la orden es: {orderID}
      </Typography>
      {cellphone && (
        <Link
          className={classes.linkContainer}
          href={`https://wa.me/57${cellphone}?text=Buen%20d%C3%ADa%2C%20su%20pedido%20es%20el%3A%20${orderID}%20y%20puede%20consultarlo%20en%20https%3A%2F%2Fdelivree.johanaltamar.com%2Forders%3Fid%3D${orderID}`}
          target="_blank"
          rel="noopener"
          underline="none"
          variant="button"
        >
          WhatsApp
        </Link>
      )}
    </Container>
  );
};

OrderInfo.propTypes = {
  cellphone: PropTypes.string.isRequired,
  orderID: PropTypes.string.isRequired,
};
