import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuList,
  TextField,
  Typography,
} from "@material-ui/core";
import db from "../../services/firebase";
import { makeStyles } from "@material-ui/core/styles";
import {
  // validateEmail,
  validateForm,
  // validatePassword,
} from "../../helpers/validations";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(0),
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& > *:nth-child(odd)": {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
    "& > *:nth-child(even)": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(4),
    },
    "& > *": {
      marginBottom: theme.spacing(4),
      maxWidth: `calc(50% - ${theme.spacing(6)}px)`,
    },
  },
  switchButton: {
    minWidth: 135,
  },
}));

const EditUserModal = ({ open, onClose, userInfo, role, setAlertSettings }) => {
  const classes = useStyles();
  const [userData, setUserData] = useState({ ...userInfo });
  const [initialUserInfo] = useState(JSON.stringify(userInfo));

  const handleUserInfoChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleUpdateUser = async () => {
    try {
      const lowerCaseObject = {};

      Object.keys(userData).forEach((feature) => {
        if (typeof userData[feature] === "string") {
          lowerCaseObject[feature] = userData[feature].toLowerCase();
        } else {
          lowerCaseObject[feature] = userData[feature];
        }
      });
      // console.log(lowerCaseObject);

      await db
        .collection(role === "customer" ? "users" : "staff")
        .doc(userData.id)
        .update(lowerCaseObject);

      setAlertSettings({
        open: true,
        message: "Usuario actualizado con éxito.",
      });

      onClose();
    } catch (error) {
      console.error(error);
      setAlertSettings({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  // const handleEmailError = (email) => {
  //   if (email) {
  //     return !validateEmail(email);
  //   }
  // };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogContent className={classes.dialogContent}>
        <Typography align="center" variant="h5" gutterBottom>
          Editar Usuario
        </Typography>

        <form className={classes.form}>
          <TextField
            fullWidth
            label="Nombre de Usuario"
            name="fullname"
            onChange={handleUserInfoChange}
            required
            value={userData.fullname}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Telefono"
            name="telephone"
            onChange={handleUserInfoChange}
            required
            value={userData.telephone}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Dirección"
            name="address"
            onChange={handleUserInfoChange}
            required
            value={userData.address}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Barrio"
            name="neighborhood"
            onChange={handleUserInfoChange}
            required
            value={userData.neighborhood}
            variant="outlined"
          />
          {/* <TextField
            error={handleEmailError(userData.email)}
            fullWidth
            helperText={
              handleEmailError(userData.email)
                ? "Debe parecer: nombre@example.com"
                : ""
            }
            label="Correo Electrónico"
            name="email"
            onChange={handleUserInfoChange}
            placeholder="tucorreo@example.com"
            required
            type="email"
            value={userData.email}
            variant="outlined"
          /> */}
          <TextField
            defaultValue={role}
            fullWidth
            label="Rol"
            name="role"
            onChange={handleUserInfoChange}
            select
            variant="outlined"
          >
            <MenuList value="customer">Clientes</MenuList>
            <MenuList value="staff">Empleados</MenuList>
            <MenuList value="delivery">Domiciliarios</MenuList>
          </TextField>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.switchButton}
          color="secondary"
          onClick={() =>
            setUserData({ ...userData, enabled: !userData.enabled })
          }
          variant={userData.enabled ? "outlined" : "contained"}
        >
          {userData.enabled ? "deshabilitar" : "habilitar"}
        </Button>
        <Button
          className={classes.switchButton}
          color="primary"
          onClick={handleUpdateUser}
          variant="contained"
          disabled={
            initialUserInfo === JSON.stringify(userData) ||
            !validateForm([
              userData.fullname,
              userData.telephone,
              userData.address,
              userData.neighborhood,
            ])
          }
        >
          Actualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserModal;
