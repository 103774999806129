import React from "react";
import {
  Container,
  TextField,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { useAppContext } from "../../../context";
import db from "../../../services/firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
  },
  textfield: {
    marginBottom: "1rem",
  },
}));

export const UserInfo = () => {
  const classes = useStyles();

  const { userInfo, setUserInfo } = useAppContext();

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setUserInfo({ ...userInfo, [id]: value });
  };

  const handleCustomerSearch = async () => {
    //Optional but requires db refactoring
    console.log(userInfo.telephone);
    const users = await db
      .collection("users")
      .where("telephone", "==", userInfo.telephone)
      .get();
    users.forEach((doc) => console.log(doc.data()));
  };

  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    setUserInfo({ ...userInfo, [name]: !userInfo[name] });
  };

  return (
    <Container className={classes.root}>
      <Typography align="center" gutterBottom variant="h5">
        Información del Cliente
      </Typography>
      <form>
        <TextField
          className={classes.textfield}
          fullWidth
          id="fullname"
          label="Cliente"
          onChange={handleInputChange}
          required
          value={userInfo.fullname}
          variant="outlined"
        />
        <TextField
          className={classes.textfield}
          fullWidth
          id="telephone"
          label="Celular"
          type="search"
          onChange={handleInputChange}
          required
          value={userInfo.telephone}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleCustomerSearch}
                  edge="end"
                >
                  <SearchOutlined />
                </IconButton>
              </InputAdornment>
            ),
            labelWidth: 70,
          }}
        />
        <TextField
          className={classes.textfield}
          fullWidth
          id="address"
          label="Dirección"
          onChange={handleInputChange}
          required={userInfo.delivery}
          disabled={!userInfo.delivery}
          value={userInfo.address}
          variant="outlined"
        />
        <TextField
          className={classes.textfield}
          fullWidth
          id="neighborhood"
          label="Barrio"
          onChange={handleInputChange}
          required={userInfo.delivery}
          disabled={!userInfo.delivery}
          value={userInfo.neighborhood}
          variant="outlined"
        />
        <TextField
          className={classes.textfield}
          fullWidth
          id="comments"
          label="Comentarios"
          multiline
          onChange={handleInputChange}
          placeholder="Esscriba aquí algún comentario sobre dirección, producto, etc"
          rows={4}
          value={userInfo.comments}
          variant="outlined"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!userInfo.delivery}
              color="primary"
              name="delivery"
              onChange={handleCheckboxChange}
            />
          }
          label="Recoge en restaurante."
        />
      </form>
    </Container>
  );
};
