import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { FormatListBulleted, Group } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  card: {
    cursor: "pointer",
    minWidth: "35%",
    maxWidth: "40%",

    "&:hover": {
      backgroundColor: "#FFA9E7",
    },

    "&:active": {
      backgroundColor: "#E866C3",
    },

    [theme.breakpoints.down("xs")]: {
      minWidth: "55%",
      "&:first-child": {
        marginBottom: theme.spacing(3),
      },
    },
  },
  cardTitle: {
    padding: `${theme.spacing(3)}px 0`,
  },
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: "center",

    "& .MuiTypography-root:first-child": {
      marginBottom: theme.spacing(3),
    },

    "& .MuiSvgIcon-root": {
      fontSize: theme.spacing(9),
    },
  },
  container: {
    backgroundColor: "rgba(82,142, 191, .50)",
    display: "flex",
    flexDirection: " column",
    minWidth: "50%",
    maxWidth: "50%",
    padding: `${theme.spacing(3)}px 0`,

    [theme.breakpoints.down("xs")]: {
      minWidth: "100vw",
      padding: `${theme.spacing(3)}px 0`,
    },
  },
  containerTitle: {
    marginBottom: theme.spacing(0),

    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const HomeOthersSection = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleCardClick = (path) => {
    history.push(path);
  };

  return (
    <div className={classes.container}>
      <Typography
        variant="h5"
        align="center"
        className={classes.containerTitle}
      >
        Atajos
      </Typography>
      <div className={classes.cardsContainer}>
        <Card
          className={classes.card}
          onClick={() => handleCardClick("/products/list")}
        >
          <CardContent className={classes.cardContent}>
            <FormatListBulleted />
            <Typography align="center" variant="h6">
              Productos
            </Typography>
          </CardContent>
        </Card>
        <Card
          className={classes.card}
          onClick={() => handleCardClick("/users?role=customer")}
        >
          <CardContent className={classes.cardContent}>
            <Group />
            <Typography align="center" variant="h6">
              Clientes
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default HomeOthersSection;
