import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

import db from "../../../services/firebase";
import CategoriesContainer from "./ProductsCategoriesContainer";
import ProductsContainer from "./ProductsProductsContainer";

export function Products({ addProductToCartFn }) {
  const initialLoading = { categories: true, products: false };
  const [loading, setLoading] = useState(initialLoading);
  const [selected, setSelected] = useState("");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  // ============== CATEGORIES FETCHING ==============
  useEffect(() => {
    let mounted = true;

    const fetchCategories = async () => {
      let categoriesNames = [];

      try {
        if (categories.length === 0) {
          const dbRef = db.collection("categories");
          const res = await dbRef.get();

          if (!res.empty) {
            res.forEach((category) => {
              const { name } = category.data();
              // console.log(name);
              categoriesNames.push({ name, id: category.id });
            });
          }
          // console.log(categoriesNames);
          if (mounted) {
            setCategories(categoriesNames);
            setLoading({ ...loading, categories: false });
          }
        }
      } catch (error) {
        console.log(error.code, error.message);
      }
    };

    fetchCategories();

    return () => (mounted = false);
    //eslint-disable-next-line
  }, []);

  // ============== PRODUCTS FETCHING BY CATEGORY ==============
  useEffect(() => {
    let mounted = true;

    const productsFetchingByCategory = async () => {
      let prodsArr = [];

      try {
        if (selected) {
          const prodsRef = db
            .collection("products")
            .where("category", "==", selected);

          const prodsResult = await prodsRef.get();

          prodsResult.forEach((item) =>
            prodsArr.push({ ...item.data(), id: item.id })
          );

          if (mounted) {
            setProducts(prodsArr);
            setLoading({ ...loading, products: false });
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    productsFetchingByCategory();

    return () => (mounted = false);
    //eslint-disable-next-line
  }, [selected]);

  const handleAddProduct = (item, qty) => {
    addProductToCartFn(item, qty);
  };

  const handleSelectCategory = (id) => {
    setSelected(id);
    setLoading({ ...loading, products: true });
  };

  return (
    <>
      {loading.categories ? (
        <CircularProgress />
      ) : (
        <>
          <CategoriesContainer
            categories={categories}
            selectedCategory={handleSelectCategory}
            selected={selected}
          />
          {/* <Divider /> */}

          {loading.products ? (
            <CircularProgress />
          ) : (
            selected && (
              <ProductsContainer
                products={products}
                addProductToCartFn={handleAddProduct}
              />
            )
          )}
        </>
      )}
    </>
  );
}

Products.propTypes = {
  addProductToCartFn: PropTypes.func.isRequired,
};
