export const timeCalc = (date) => {
  const now = new Date().getTime();
  const diff = now - date;

  const dateValue = diff / 60000; // value in minutes
  let formatted;
  if (dateValue < 60) {
    formatted = `${parseInt(dateValue)} minutos`; // return minutes
  } else if (dateValue >= 60) {
    formatted = `${parseInt(dateValue / 60)} horas ${
      dateValue % 60 && parseInt(dateValue % 60) + " minutos"
    } `;
  }
  
  return formatted;
};

export const openingTimestamp = (openingHour) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth()
  const day = date.getDate()

  const today = new Date(year, month, day, openingHour)
  const timestamp = today.getTime();

  return timestamp
}
