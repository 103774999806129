import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Add, Remove } from "@material-ui/icons";
import {
  IconButton,
  TextField,
  Card,
  Typography,
  CardContent,
  CardActionArea,
  CardActions,
} from "@material-ui/core";

import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "calc(100% / 3)",
    marginRight: theme.spacing(5),
    minWidth: "calc(100% / 3)",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100% / 2 - 20px)",
      minWidth: "calc(100% / 2 - 20px)",
    },

    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      maxWidth: "100%",
      marginBottom: theme.spacing(2),
    },

    "&:last-child": {
      marginBottom: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  actions: {
    alignSelf: "flex-end",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    maxWidth: 180,
    margin: "0px auto",

    "& input": {
      textAlign: "center",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function ProductCard({
  url,
  name,
  category,
  description,
  addProductFn,
  id,
  price,
}) {
  const classes = useStyles();
  const [counter, setCounter] = useState(1);
  const countRef = useRef(counter);
  countRef.current = counter;

  const handleCounter = (step) => {
    setCounter((prev) => parseInt(prev + step));
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (value === "0") {
      setCounter(parseInt(1));
    } else if (value === "") {
      setCounter(value);
      setTimeout(() => {
        if (countRef.current === "") {
          setCounter(1);
        }
      }, 3000);
    } else {
      setCounter(parseInt(value));
    }
  };

  const handleAddProduct = () => {
    addProductFn({ url, name, category, id, price }, counter);
    setCounter(1);
  };

  return (
    <Card
      className={classes.root}
      key={id}
    >
      <CardActionArea onClick={handleAddProduct}>
        <CardContent>
          <Typography
            className={classes.title}
            gutterBottom
            variant="h5"
            component="h2"
          >
            {name}
            <span>${price * counter}</span>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <IconButton
          color="secondary"
          disabled={counter === 1 || counter === ""}
          disableFocusRipple
          disableRipple
          onClick={() => handleCounter(-1)}
        >
          <Remove />
        </IconButton>
        <TextField
          variant="outlined"
          value={counter}
          type="number"
          onChange={handleInputChange}
        />
        <IconButton
          color="primary"
          disableFocusRipple
          disableRipple
          onClick={() => handleCounter(1)}
        >
          <Add />
        </IconButton>
      </CardActions>
    </Card>
  );
}

ProductCard.propTypes = {
  addProductFn: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};
