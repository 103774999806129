import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "rgba(155, 176, 194, .50)", //82,142, 191
    minWidth: "50%",
    maxWidth: "50%",
    padding: `${theme.spacing(6)}px 0`,

    [theme.breakpoints.down("xs")]: {
      minWidth: "100vw",
      padding: `${theme.spacing(3)}px 0`,
    },
  },
  card: {
    height: "100%",
    margin: "auto",
    maxWidth: "75%",

    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
  },
  cardTitle: {
    padding: `${theme.spacing(3)}px 0`,
  },
  cardContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "& .MuiTypography-root.MuiTypography-h6:first-child": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const Balance = ({ total = 0, orders = 0 }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <Typography align="center" className={classes.cardTitle} variant="h5">
          Balance Diario
        </Typography>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6">
            Ordernes Completadas: {orders}
          </Typography>
          <Typography variant="h6">
            Balance: $ {`${total.toLocaleString("de-DE")}`}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default Balance;

Balance.propTypes = {
  total: PropTypes.number,
  orders: PropTypes.number,
};
