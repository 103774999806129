import React from "react";
import {
  Container,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

export const FoundOrders = ({ orders,  selectedOrder, setSelectedOrder}) => {

  const handleRowClick = (id) => {
    if (selectedOrder === id) {
      return setSelectedOrder(null);
    }
    setSelectedOrder(id);
  };

  return (
    <Container>
      <Typography align="center" variant="h5">
        Ordenes Encontradas
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Usuario</TableCell>
            <TableCell>Celular</TableCell>
            <TableCell>Pedido</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Estado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow
              key={row.id}
              selected={row.id === selectedOrder}
              onClick={() => handleRowClick(row.id)}
            >
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.fullname}</TableCell>
              <TableCell>{row.telephone}</TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>
                {new Date(row?.timestamp)?.toLocaleString()}
              </TableCell>
              <TableCell>{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};
