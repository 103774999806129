import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AppBar from './components/AppBar';
import Drawer from './components/Drawer';
import './App.css';

import Routes from './Routes';
import { auth } from './services/firebase';

import { AppContext } from './context';

function App() {
  const location = useLocation();

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState('hola');
  const [userUid, setUserUid] = useState('');
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [logoutModalStatus, setLogoutModalStatus] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    try {
      auth.onAuthStateChanged(function (user) {
        if (user) {
          userHasAuthenticated(true);
          setUserUid(user.uid);
        } else {
          userHasAuthenticated(false);
          setUserUid('');
        }
      });
    } catch (e) {
      console.log(e);
    }
    setTimeout(() => {
      setIsAuthenticating(false);
    }, 1000);
  };

  return (
    !isAuthenticating && (
      <AppContext.Provider
        value={{
          isAuthenticated,
          userHasAuthenticated,
          userUid,
          setUserUid,
          drawerStatus,
          setDrawerStatus,
          logoutModalStatus,
          setLogoutModalStatus,
        }}
      >
        <div
          style={
            location.pathname === '/login' ? { display: 'none' } : undefined
          }
        >
          <AppBar title="Delivree App" />
          <Drawer isAuthenticated={isAuthenticated} />
        </div>
        <Routes />
      </AppContext.Provider>
    )
  );
}

export default App;
