import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { useAppContext } from '../context';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& a': {
      textDecoration: 'none',
      color: '#fff',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  button: {
    color: '#ffffff',
  },
}));

export default function ButtonAppBar({ title }) {
  const classes = useStyles();

  const {
    isAuthenticated,
    drawerStatus,
    setDrawerStatus,
    setLogoutModalStatus,
  } = useAppContext();

  const handleLogout = async () => {
    setLogoutModalStatus(true);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {/* To edit function, go to Drawer Component */}
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerStatus(!drawerStatus)}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            <Link to="/">{title}</Link>
          </Typography>
          {isAuthenticated ? (
            <Button
              className={classes.button}
              color="inherit"
              onClick={handleLogout}
            >
              Salir
            </Button>
          ) : (
            <Link to="/login">
              <Button className={classes.button} color="default">
                Login
              </Button>
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
