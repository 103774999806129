import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    marginRight: theme.spacing(3),
    maxWidth: 140,
    minWidth: 140,
    textAlign: "center",

    "&:last-child": {
      marginRight: theme.spacing(0),
    },

    "&:hover": {
      backgroundColor: "#FFA9E7",
      color: "#000000",
      fontWeight: "normal",
    },

    "& .MuiCardContent-root": {
      alignItems: "center",
      display: "flex",
      height: `calc(100% - ${theme.spacing(4)}px)`,
      justifyContent: "center",
      padding: theme.spacing(2),
      textTransform: "capitalize",
    },
  },
  cardContainer: {
    display: "flex",
    maxHeight: 60,
    maxWidth: "100vw",
    margin: theme.spacing(1),
    overflowX: "auto",
    padding: theme.spacing(1),
  },
  isSelected: {
    backgroundColor: "#E866C3",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
}));

const CategoryCard = ({ name, ...rest }) => {
  return (
    <Card {...rest}>
      <CardContent>{name}</CardContent>
    </Card>
  );
};

const ProductsCategoriesContainer = ({
  categories,
  selectedCategory,
  selected,
}) => {
  const classes = useStyles();

  return (
    categories.length >= 0 && (
      <div className={classes.cardContainer}>
        {categories.map(({ id, name }) => (
          <CategoryCard
            className={`${classes.card} ${
              selected === id ? classes.isSelected : undefined
            }`}
            name={name}
            key={id}
            onClick={() => selectedCategory(id)}
          />
        ))}
      </div>
    )
  );
};

export default ProductsCategoriesContainer;

ProductsCategoriesContainer.propTypes = {
  categories: PropTypes.array.isRequired,
  selectedCategory: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};
