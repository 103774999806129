import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SearchOrder } from "../EditOrder/SearchOrder";

import db from "../../../services/firebase";
import { FoundOrders } from "../EditOrder/FoundOrders";
import { DisplayOrder } from "./DisplayOrder";

const useStyles = makeStyles(() => ({
  title: {
    "& .MuiTypography-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  content: {
    minHeight: "50vh",
    padding: 0,
  },
  dialog: {
    "& .MuiDialog-container .MuiPaper-root": {
      minHeight: "90%",
      maxHeight: "90%",
    },
  },
}));

const SearchOrderModal = ({ open, handleClose }) => {
  const classes = useStyles();

  const [orderStep, setOrderStep] = useState(1);
  const initialState = {
    fullname: "",
    telephone: "",
    orderID: "",
  };
  const [searchInfo, setSearchInfo] = useState(initialState);
  const [foundOrders, setFoundOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderInfo, setOrderInfo] = useState({});

  // HANDLERS
  const handleNext = () => {
    setOrderStep(orderStep + 1);
  };
  const handleLast = () => {
    setOrderStep(orderStep - 1);
  };

  const handleSearchOrder = async () => {
    let searchBy = Object.keys(searchInfo).find(
      (item) => searchInfo[item].length > 0
    );

    let searchCriteria = "fullname";
    switch (searchBy) {
      case "fullname":
        searchCriteria = "fullname";
        break;
      case "telephone":
        searchCriteria = "telephone";
        break;
      case "orderID":
        searchCriteria = searchBy;
        break;
      default:
        break;
    }

    try {
      if (searchCriteria !== "orderID") {
        const { docs } = await db
          .collection("orders")
          .where(searchCriteria, "==", searchInfo[searchBy])
          .limit(10)
          .get();

        if (docs.length) {
          const newArr = [];
          docs.map((doc) => newArr.push({ ...doc.data(), id: doc.id }));
          setFoundOrders(newArr);
          handleNext();
        } else {
          // LLAMAR NOTIFICACION
          console.log("No orders found.");
        }
      } else {
        const res = await db
          .collection("orders")
          .doc(searchInfo[searchBy])
          .get();
        // console.log(res.data());
        setFoundOrders([{ id: searchInfo[searchBy], ...res.data() }]);
        handleNext();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateForm = () => {
    const requiredFields = Object.keys(searchInfo);
    return requiredFields.some((item) => searchInfo[item].length > 0);
  };

  // Set OrderInfo when an order is selected
  useEffect(() => {
    if (selectedOrder) {
      setOrderInfo(foundOrders.find((order) => order.id === selectedOrder));
    } else {
      setOrderInfo({});
    }
  }, [selectedOrder, foundOrders]);

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>Buscar Pedido</DialogTitle>
      <DialogContent className={classes.content}>
        {orderStep === 1 && (
          <SearchOrder searchInfo={searchInfo} setSearchInfo={setSearchInfo} />
        )}
        {orderStep === 2 && (
          <FoundOrders
            orders={foundOrders}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
          />
        )}
        {orderStep === 3 && <DisplayOrder orderInfo={orderInfo} />}
      </DialogContent>
      <DialogActions>
        {orderStep > 1 && orderStep < 5 && (
          <Button color="secondary" onClick={handleLast}>
            Anterior
          </Button>
        )}
        {orderStep === 1 && (
          <Button
            color="primary"
            disabled={!validateForm()}
            onClick={handleSearchOrder}
          >
            Buscar Orden
          </Button>
        )}
        {orderStep === 2 && (
          <Button
            color="primary"
            disabled={!selectedOrder}
            onClick={handleNext}
          >
            Siguiente
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SearchOrderModal;
