import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import HomeIcon from "@material-ui/icons/Home";
import OrdersIcon from "@material-ui/icons/MoveToInbox";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PersonIcon from "@material-ui/icons/Person";
import SignOutIcon from "@material-ui/icons/PowerSettingsNew";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";

import BrandLogo from "../icons/brandLogo.png";
import { useAppContext } from "../context";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: "1rem",
  },
  list: {
    overflowX: "hidden",
    width: 250,
    "& a": {
      textDecoration: "none",
    },
  },
  listItemExpand: {
    width: 40,
    textAlign: "center",
  },
  logo: {
    cursor: "pointer",
    height: 120,
    margin: "1rem auto",
    width: 120,
    "& img": {
      borderRadius: "30%",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const CustomListItem = ({
  icon,
  expandable = false,
  expandValue,
  handleClick,
  nested = false,
  logout = false,
  redirect = false,
  text,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { setDrawerStatus, setLogoutModalStatus } = useAppContext();

  const handleFunctions = () => {
    if (logout) {
      setDrawerStatus(false);
      setLogoutModalStatus(true);
    } else if (redirect) {
      history.push(redirect);
      setDrawerStatus(false);
    } else if (expandable) {
      handleClick(expandable);
    }
  };

  return (
    <ListItem
      button
      onClick={handleFunctions}
      className={nested ? classes.nested : undefined}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={text} />
      {expandable && (
        <div className={classes.listItemExpand}>
          {expandValue ? <ExpandLess /> : <ExpandMore />}
        </div>
      )}
    </ListItem>
  );
};

const DrawerList = () => {
  const classes = useStyles();
  const history = useHistory();

  const initialState = {
    orders: false,
    users: false,
    products: false,
  };
  const [open, setOpen] = useState(initialState);
  const { setDrawerStatus } = useAppContext();

  const handleClick = (category) => {
    // console.log(category)
    setOpen({ ...open, [category]: !open[category] });
  };

  return (
    <div className={`${classes.list} drawer-list`} role="presentation">
      <div
        className={classes.logo}
        onClick={() => {
          history.push("/");
          setDrawerStatus(false);
        }}
      >
        <img src={BrandLogo} width="100%" alt="Logo" />
      </div>

      <Divider className={classes.divider} light />

      <List>
        <CustomListItem redirect="/" text="Inicio" icon={<HomeIcon />} />
        <CustomListItem
          icon={<OrdersIcon />}
          expandable="orders"
          expandValue={open.orders}
          handleClick={handleClick}
          toggle={false}
          text="Pedidos"
        />
        <Collapse in={open.orders} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <CustomListItem
              redirect="/orders/waiting"
              nested
              text="En Espera"
            />
            <CustomListItem
              redirect="/orders/accepted"
              nested
              text="Aceptados"
            />
            <CustomListItem
              redirect="/orders/on-preparation"
              nested
              text="En Curso"
            />
            <CustomListItem redirect="/orders/ready" nested text="Listos" />
            <CustomListItem
              redirect="/orders/on-delivery"
              nested
              text="En Reparto"
            />
            <CustomListItem
              redirect="/orders/completed"
              nested
              text="Completados"
            />
          </List>
        </Collapse>

        <CustomListItem
          icon={<RestaurantMenuIcon />}
          text="Productos"
          expandable="products"
          expandValue={open.products}
          handleClick={handleClick}
          toggle={false}
        />
        <Collapse in={open.products} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <CustomListItem
              redirect="/products/add"
              nested
              text="Agregar Producto"
            />
            <CustomListItem
              redirect="/products/edit"
              nested
              text="Editar Producto"
            />
          </List>
        </Collapse>
        <CustomListItem
          icon={<PersonIcon />}
          expandable="users"
          expandValue={open.users}
          handleClick={handleClick}
          toggle={false}
          text="Usuarios"
        />
        <Collapse in={open.users} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <CustomListItem
              redirect="/users?role=customer"
              nested
              text="Clientes"
            />
            <CustomListItem
              redirect="/users?role=staff"
              nested
              text="Empleados"
            />
            <CustomListItem
              redirect="/users?role=delivery"
              nested
              text="Domiciliarios"
            />
          </List>
        </Collapse>
        <CustomListItem icon={<SignOutIcon />} logout text="Cerrar Sesión" />
      </List>
    </div>
  );
};

export default DrawerList;
