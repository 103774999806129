import React from "react";
import { useAppContext } from "../../../context";
import { ProductsTable } from "../AddOrder/CurrentShoppingCartProductsTable";
import { Products } from "../AddOrder/Products";

export const TargetOrder = ({ addProducts }) => {
  const { cart, setCart } = useAppContext();

  const addProductToCart = (item, qty) => {
    let cartArr = [...cart];
    const productIndex = cart.findIndex(
      (product) => product.id === item.id
    );
    if (productIndex !== -1) {
      cartArr[productIndex] = {
        ...cartArr[productIndex],
        qty: cartArr[productIndex]["qty"] + qty,
      };
      setCart(cartArr);
    } else {
      setCart([{ ...item, qty }, ...cart]);
    }
  };

  return !addProducts ? (
    <ProductsTable />
  ) : (
    <Products addProductToCartFn={addProductToCart} />
  );
};
