import React, { useState } from "react";
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "../Alert";
import db, { storage } from "../../services/firebase";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0),
    textAlign: "center",
    "& .MuiFormControl-root:first-child": {
      textAlign: "left",
    },
  },
  form: {
    // maxWidth: "350px",
    // margin: "auto",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      marginBottom: theme.spacing(4),
      maxWidth: 350,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "calc((100% - 280px) / 2)",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "calc((100% - 350px) / 2)",
    },
  },
  imageContainer: {
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 250,
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 280,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 350,
    },
  },
  searchTextfield: {
    margin: "auto",
    marginBottom: theme.spacing(4),
    
    [theme.breakpoints.down("xs")]: {
      maxWidth: "350px",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "calc(100vw - (50vw - 280px))",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "calc(100vw - (50vw - 350px))",
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
    "&:last-child": {
      marginBottom: theme.spacing(0),
    },
  },
  title: {
    padding: theme.spacing(3),
  },
}));

const ProductsContainer = ({ title, option }) => {
  const classes = useStyles();
  const initialState = {
    category: "burguers",
    description: "",
    image: null,
    name: "",
    price: "",
    productID: "",
    status: "active",
    url: "",
  };
  const [values, setValues] = useState(initialState);
  const [openAlert, setOpenAlert] = useState(false);

  const handleSearchProduct = async (event) => {
    event.preventDefault();
    const { productID } = values;
    if (productID) {
      try {
        const docRef = db.collection("products").doc(productID);
        const res = await docRef.get();
        // console.log(res.exists ? res.data() : "No se encuentra información");
        if (res.exists) {
          const data = res.data();
          setValues({ ...values, ...data });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleKeyPressSearch = (event) => {
    if (event.key === "Enter") {
      handleSearchProduct(event);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name !== "image") {
      setValues({ ...values, [name]: value });
    } else {
      if (event.target.files[0]) {
        setValues({ ...values, [name]: event.target.files[0] });
      }
    }
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setValues({ ...values, [name]: checked ? "active" : "inactive" });
  };

  const validateForm = () => {
    let validated = false;
    if (
      values.name &&
      values.category &&
      values.price &&
      values.url &&
      values.description
    ) {
      validated = true;
    }
    return validated;
  };

  const handleUpload = () => {
    const uploadTask = storage
      .ref(`images/products/${values.category}/${values.image.name}`)
      .put(values.image);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed", // or 'state_changed'
      function (snapshot) {},
      function (error) {
        // A full list of error codes is available at
        // https://google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      function () {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          setValues({ ...values, url: downloadURL, image: null });
          // console.log("File available at", downloadURL);
        });
      }
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (option === "add") {
        await db.collection("products").add({
          name: values.name,
          category: values.category,
          price: parseInt(values.price),
          description: values.description,
          url: values.url,
          status: values.status,
        });
        setOpenAlert(true);
        setValues(initialState);
      } else if (option === "edit") {
        await db
          .collection("products")
          .doc(values.productID)
          .update({
            name: values.name,
            category: values.category,
            price: parseInt(values.price),
            description: values.description,
            url: values.url,
            status: values.status,
          });
        setOpenAlert(true);
        setValues(initialState);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container className={classes.container} maxWidth="xl">
      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        message={option === "edit" ? "Producto modificado con éxito" : "Producto agregado con éxito"}
      />
      <Typography className={classes.title} align="center" variant="h5">
        {title}
      </Typography>
      {option === "edit" && (
        <TextField
          autoFocus
          className={classes.searchTextfield}
          fullWidth
          id="productID"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton onClick={handleSearchProduct}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="ID del producto"
          name="productID"
          onChange={handleInputChange}
          onKeyUp={handleKeyPressSearch}
          placeholder="Ingrese ID del producto"
          type="search"
          value={values.productID}
          variant="outlined"
        />
      )}
      <Grid container>
        <Grid item xs={12} sm={6}>
          <form onSubmit={(e) => handleSubmit(e)} className={classes.form}>
            <TextField
              className={classes.textField}
              defaultValue="burguers"
              fullWidth
              name="category"
              label="Categoría"
              onChange={handleInputChange}
              select
              value={values.category}
              variant="outlined"
            >
              <MenuItem value="burguers">Hamburguesa</MenuItem>
              <MenuItem value="hot-dogs">Perro Caliente</MenuItem>
              <MenuItem value="sandwich">Sandwich</MenuItem>
              <MenuItem value="salads">Ensalada</MenuItem>
              <MenuItem value="cocktails">Coctél</MenuItem>
              <MenuItem value="drinks">Bebida</MenuItem>
            </TextField>
            <TextField
              className={classes.textField}
              name="name"
              label="Nombre del producto"
              fullWidth
              onChange={handleInputChange}
              placeholder="Mexican@"
              value={values.name}
              variant="outlined"
              required
            />
            <TextField
              className={classes.textField}
              name="price"
              fullWidth
              label="Precio"
              onChange={handleInputChange}
              placeholder="5000"
              type="number"
              value={values.price}
              variant="outlined"
              required
            />
            <TextField
              className={classes.textField}
              fullWidth
              name="image"
              InputProps={{ accept: "image/png, image/jpeg" }}
              onChange={handleInputChange}
              type="file"
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              fullWidth
              name="description"
              label="Descripción"
              rows={3}
              rowsMax={3}
              multiline
              onChange={handleInputChange}
              placeholder="Ingresa los ingredientes o una descripción del producto"
              value={values.description}
              variant="outlined"
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!validateForm()}
            >
              {option === "add" && "Crear Producto"}
              {option === "edit" && "Editar Producto"}
            </Button>
          </form>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
          <Typography align="center" variant="h6" gutterBottom>
            Imagen Previa
          </Typography>
          <div className={classes.imageContainer}>
            {!values.image && !values.url && (
              <img
                className={classes.image}
                src={
                  "https://via.placeholder.com/250.svg?text=Agrega%20una%20imagen"
                }
                alt="Selecciona una imagen"
                width="100%"
              />
            )}
            {values.image && !values.url && (
              <>
                <img
                  src={URL.createObjectURL(values.image)}
                  alt="Imagen seleccionada"
                  width="100%"
                />
                <Button
                  color="primary"
                  onClick={handleUpload}
                  startIcon={<CloudUploadIcon />}
                  style={{ margin: "16px" }}
                  variant="outlined"
                >
                  {"Subir Imagen"}
                </Button>
              </>
            )}
            {values.url && !values.image && (
              <>
                <img src={values.url} alt="Imagen Actual" width="100%" />
                <Button
                  color="primary"
                  disabled
                  onClick={handleUpload}
                  startIcon={<CloudDoneIcon />}
                  style={{ margin: "16px" }}
                  variant="outlined"
                >
                  {"Imagen Subida"}
                </Button>
              </>
            )}
            {values.image && values.url && (
              <>
                <img
                  src={URL.createObjectURL(values.image)}
                  alt=""
                  width="100%"
                />
                <Button
                  color="primary"
                  onClick={handleUpload}
                  startIcon={<CloudUploadIcon />}
                  style={{ margin: "16px" }}
                  variant="outlined"
                >
                  {"Actualizar Imagen"}
                </Button>
              </>
            )}
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={values.status === "active"}
                onChange={handleSwitchChange}
                name="status"
              />
            }
            label="Producto Activo"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductsContainer;
