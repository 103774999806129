import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

export const OrdersStatusCard = ({
  title,
  name,
  link,
  orders,
  cardClasses,
  ...rest
}) => {
  const history = useHistory();

  return (
    <Card className={cardClasses} onClick={() => history.push(link)} {...rest}>
      <CardContent>
        <Typography variant="h6" component="h6" align="center">
          {title}
        </Typography>
        <Typography variant="h2" component="h2" align="center">
          {orders || "-"}
        </Typography>
      </CardContent>
    </Card>
  );
};

OrdersStatusCard.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  orders: PropTypes.number,
  cardClasses: PropTypes.string.isRequired,
};
