export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateForm = (requiredFields) => {
  let validForm = true;
  requiredFields.forEach((field) => {
    if (field.length === 0) {
      return (validForm = false);
    }
  });

  return validForm;
};

export const validatePassword = (password, newUser) => {
  let validPassword = true
  if(password){
    if (
      password.match(/[a-z]/g) && //lowercase letter
      password.match(/[A-Z]/g) && //uppercase letter
      password.match(/[0-9]/g) && //numeric
      // password.match(/[^a-zA-Z\d]/g) && //special character
      password.length >= 8
    ) {
      return true;
    }
    return false;
  }
  if(newUser){
    return false
  }
  return validPassword
};
