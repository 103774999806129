import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import DrawerList from './DrawerList';
import { useAppContext } from '../context';
import DrawerLogoutModal from './DrawerLogoutModal';
import { auth } from '../services/firebase';
import { useHistory } from 'react-router-dom';

const Drawer = () => {
  const history = useHistory();

  const {
    drawerStatus,
    setDrawerStatus,
    logoutModalStatus,
    setLogoutModalStatus,
    userHasAuthenticated,
  } = useAppContext();

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerStatus(open);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setLogoutModalStatus(false);
      userHasAuthenticated(false);
      history.push('/login');
    } catch (error) {
      console.log(error.code, error.message);
    }
  };

  const handleClose = () => {
    setLogoutModalStatus(false);
  };

  return (
    <div >
      <>
        <SwipeableDrawer
          anchor="left"
          open={drawerStatus}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          transitionDuration={500}
          disableBackdropTransition
          disableDiscovery
        >
          <DrawerList />
        </SwipeableDrawer>
        <DrawerLogoutModal
          onClose={handleClose}
          open={logoutModalStatus}
          onLogout={handleLogout}
        />
      </>
    </div>
  );
};

export default Drawer;
