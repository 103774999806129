import React from "react";
import { Container, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 400,
  },
  title: {
    marginBottom: "1rem",
  },
  textfield: {},
}));

export const SearchOrder = ({ searchInfo, setSearchInfo }) => {
  const classes = useStyles();

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setSearchInfo({ ...searchInfo, [id]: value });
  };

  return (
    <Container className={classes.container}>
      <Typography align="center" className={classes.title} variant="h5">
        Busca la orden
      </Typography>
      <form>
        <TextField
          className={classes.textfield}
          color="primary"
          fullWidth
          id="fullname"
          label="Nombre del Cliente"
          margin="normal"
          onChange={handleInputChange}
          value={searchInfo.fullname}
          variant="outlined"
          type="search"
        />
        <TextField
          className={classes.textfield}
          color="primary"
          fullWidth
          id="telephone"
          label="Celular"
          margin="normal"
          onChange={handleInputChange}
          value={searchInfo.telephone}
          variant="outlined"
          type="search"
        />
        <TextField
          className={classes.textfield}
          color="primary"
          fullWidth
          id="orderID"
          label="ID de la orden"
          margin="normal"
          onChange={handleInputChange}
          value={searchInfo.orderID}
          variant="outlined"
          type="search"
        />
      </form>
    </Container>
  );
};
