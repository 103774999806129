import React, { useEffect } from "react";
import { UserInfo } from "../AddOrder/UserInfo";

import { useAppContext } from "../../../context";

export const EditUserInfo = ({ orderInfo }) => {
  const { setUserInfo } = useAppContext();

  useEffect(() => {
    const userObject = {};
    if (Object.keys(orderInfo).length > 0) {
      userObject.fullname = orderInfo.fullname;
      userObject.telephone = orderInfo.telephone;
      userObject.address = orderInfo.address;
      userObject.neighborhood = orderInfo.neighborhood;
      userObject.comments = orderInfo.comments;
      userObject.delivery = orderInfo.delivery;
    }
    setUserInfo(userObject);
  }, [orderInfo, setUserInfo]);

  return <UserInfo />;
};
