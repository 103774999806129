import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SignIn from './screens/SignInScreen';
import Home from './screens/HomeScreen';
import OrdersScreens from "./screens/OrdersScreens"
import ProductsScreens from "./screens/ProductScreens"
import UsersScreen from "./screens/UsersScreen"
import ProtectedRoute from './components/CustomRoute';
// import NotFound from './containers/NotFound';

export default function Routes() {
  return (
    <Switch>
      <Route path="/login">
        <SignIn />
      </Route>
      <ProtectedRoute exact path="/">
        <Home />
      </ProtectedRoute>
      <ProtectedRoute path="/orders/:orderStatus">
        <OrdersScreens />
      </ProtectedRoute>
      <ProtectedRoute path="/products/:productOption">
        <ProductsScreens />
      </ProtectedRoute>
      <ProtectedRoute path="/users">
        <UsersScreen />
      </ProtectedRoute>
      {/* <Route exact path="/action">
        <Action />
      </Route>
      <Route path="/action/:id">
        <Action />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route path="/login/confirmationCode">
        <Signup newUser="confirmationCode" />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <Route>
        <NotFound />
      </Route> */}
    </Switch>
  );
}
