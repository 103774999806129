import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Orders from "../components/HomeOrdersSection/index";
import Balance from "../components/HomeOrdersSection/HomeBalanceSection";
import Others from "../components/HomeOrdersSection/HomeOthersSection";

import { openingTimestamp } from "../helpers/timeCalc";
import { openingTime } from "../utils/businessInfo";
import db from "../services/firebase";

const useStyles = makeStyles((theme) => ({
  bottomSection: {
    display: "flex",
    flexWrap: "wrap",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 56px)",
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 64px)",
    },
    "& > *": {
      flex: "1",
    },
  },
}));

const HomeScreen = () => {
  const classes = useStyles();
  const [orderStatus, setorderStatus] = useState({});
  const [total, setTotal] = useState(0);

  const days = openingTime;
  const weekDay = new Date().getDay();
  // console.log(days[weekDay], openingTimestamp(days[weekDay]));

  useEffect(() => {
    const dbRef = db
      .collection("orders")
      .where("timestamp", ">=", openingTimestamp(days[weekDay]));

    let orderStatusSanpshot = {};
    let balance = 0;

    let unsubscribe = dbRef.onSnapshot(async (querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        const { status, total } = doc.data();
        if (orderStatusSanpshot?.[status]) {
          orderStatusSanpshot[status] += 1;
        } else {
          orderStatusSanpshot[status] = 1;
        }

        if (status === "order completed") {
          balance += total;
        }
      });
      // console.log(orderStatusSanpshot);

      const dbRef = db
        .collection("cancelledOrders")
        .where("timestamp", ">=", openingTimestamp(days[weekDay]));

      const ordersRes = await dbRef.get();

      setorderStatus({
        ...orderStatus,
        ...orderStatusSanpshot,
        cancelled: ordersRes.size,
      });
      setTotal(balance);

      orderStatusSanpshot = {};
      balance = 0;
    });

    return () => {
      unsubscribe();
    };

    //eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <Orders orders={orderStatus} />
      <div className={classes.bottomSection}>
        <Balance total={total} orders={orderStatus["order completed"]} />
        <Others />
      </div>
    </div>
  );
};

export default HomeScreen;
