import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SearchOrder } from "./SearchOrder";
import { FoundOrders } from "./FoundOrders";
import { TargetOrder } from "./TargetOrder";
import { EditUserInfo } from "./EditUserInfo";

import { usePersistedState } from "../../../hooks/usePersistedState";
import { AppContext } from "../../../context";
import db from "../../../services/firebase";
import { OrderInfo } from "../AddOrder/OrderInfo";
import { cartAccum } from "../../../helpers/cartAccum";

const useStyles = makeStyles(() => ({
  title: {
    "& .MuiTypography-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  content: {
    minHeight: "50vh",
    padding: 0,
  },
  dialog: {
    "& .MuiDialog-container .MuiPaper-root": {
      minHeight: "90%",
      maxHeight: "90%",
    },
  },
}));

const EditOrderModal = ({ open, handleClose }) => {
  const classes = useStyles();

  const initialUserInfo = {
    fullname: "",
    telephone: "",
    address: "",
    neighborhood: "",
    comments: "",
    delivery: true,
  };
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const [cart, setCart] = usePersistedState("delivreeCart", []);
  const [total, setTotal] = useState(0);
  const [orderStep, setOrderStep] = useState(1);

  const initialState = {
    fullname: "",
    telephone: "",
    orderID: "",
  };
  const [searchInfo, setSearchInfo] = useState(initialState);
  const [foundOrders, setFoundOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [addProducts, setAddProducts] = useState(false); //Used to switch components in target component.
  const [orderInfo, setOrderInfo] = useState({});

  //============================================================================
  // Handlers
  //============================================================================
  const handleNext = () => {
    setOrderStep(orderStep + 1);
  };

  const handleLast = () => {
    setOrderStep(orderStep - 1);
  };

  const handleSearchOrder = async () => {
    let searchBy = Object.keys(searchInfo).find(
      (item) => searchInfo[item].length > 0
    );

    let searchCriteria = "fullname";
    switch (searchBy) {
      case "fullname":
        searchCriteria = "fullname";
        break;
      case "telephone":
        searchCriteria = "telephone";
        break;
      case "orderID":
        searchCriteria = searchBy;
        break;
      default:
        break;
    }

    try {
      if (searchCriteria !== "orderID") {
        const { docs } = await db
          .collection("orders")
          .where(searchCriteria, "==", searchInfo[searchBy])
          .limit(10)
          .get();

        if (docs.length) {
          const newArr = [];
          docs.map((doc) => newArr.push({ ...doc.data(), id: doc.id }));
          setFoundOrders(newArr);
          handleNext();
        } else {
          // LLAMAR NOTIFICACION
          console.log("No orders found.");
        }
      } else {
        const res = await db
          .collection("orders")
          .doc(searchInfo[searchBy])
          .get();
        // console.log(res.data());
        setFoundOrders([{ id: searchInfo[searchBy], ...res.data() }]);
        handleNext();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrderSelected = () => {
    setCart(foundOrders.find((item) => item.id === selectedOrder).cart);
    handleNext();
  };

  const handleManageProducts = () => {
    setAddProducts(!addProducts);
  };

  const handleUpdateOrder = async () => {
    const orderObject = {
      cart,
      fullname: userInfo.fullname,
      telephone: userInfo.telephone,
      delivery: userInfo.delivery,
      address: userInfo.address,
      neighborhood: userInfo.neighborhood,
      comments: userInfo.comments,
      total,
      status: "received by restaurant",
      uid: "guest",
    };

    try {
      await db.collection("orders").doc(selectedOrder).update(orderObject);
      handleNext();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const validateForm = () => {
    const requiredFields = Object.keys(searchInfo);
    return requiredFields.some((item) => searchInfo[item].length > 0);
  };

  const handleCloseEditOrderModal = () => {
    setUserInfo(initialUserInfo);
    setCart([]);
    setTotal(0);
    setOrderStep(1);
    setSearchInfo(initialState);
    setFoundOrders([]);
    setSelectedOrder(null);
    setAddProducts(false);
    setOrderInfo({});
    handleClose();
  };

  //============================================================================
  // useEffects Hooks
  //============================================================================

  // Total Calculation
  useEffect(() => {
    let mounted = true;
    if (cart.length > 0) {
      const accum = cartAccum(cart);
      if (mounted) {
        setTotal(accum);
      }
    } else {
      setTotal(0);
    }

    return () => (mounted = false);
  }, [cart]);

  // Set OrderInfo when an order is selected
  useEffect(() => {
    if (selectedOrder) {
      setOrderInfo(foundOrders.find((order) => order.id === selectedOrder));
    } else {
      setOrderInfo({});
    }
  }, [selectedOrder, foundOrders]);

  // Keep selectedOrder value as null in steps 1 and 2
  useEffect(() => {
    if (orderStep < 3) {
      setSelectedOrder(null);
      setCart([]);
    }
  }, [orderStep, setCart]);
  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>
        Editar Pedido
        <span>Pedido: $ {total.toLocaleString("DE-de")}</span>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <AppContext.Provider value={{ cart, setCart, userInfo, setUserInfo }}>
          {orderStep === 1 && (
            <SearchOrder
              searchInfo={searchInfo}
              setSearchInfo={setSearchInfo}
            />
          )}
          {orderStep === 2 && (
            <FoundOrders
              orders={foundOrders}
              selectedOrder={selectedOrder}
              setSelectedOrder={setSelectedOrder}
            />
          )}
          {orderStep === 3 && <TargetOrder addProducts={addProducts} />}
          {orderStep === 4 && <EditUserInfo orderInfo={orderInfo} />}
          {orderStep === 5 && (
            <OrderInfo
              orderID={selectedOrder}
              cellphone={userInfo.telephone}
              message="Orden Actualizada"
            />
          )}
        </AppContext.Provider>
      </DialogContent>
      <DialogActions>
        {orderStep > 1 && orderStep < 5 && (
          <Button color="secondary" onClick={handleLast}>
            Anterior
          </Button>
        )}
        {orderStep === 1 && (
          <Button
            color="primary"
            disabled={!validateForm()}
            onClick={handleSearchOrder}
          >
            Buscar Orden
          </Button>
        )}
        {orderStep === 2 && (
          <Button
            color="primary"
            disabled={!selectedOrder}
            onClick={handleOrderSelected}
          >
            Siguiente
          </Button>
        )}
        {orderStep === 3 && (
          <>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleManageProducts}
            >{`${!addProducts ? "Agregar" : "Editar"} Productos`}</Button>
            <Button color="primary" onClick={handleNext}>
              Siguiente
            </Button>
          </>
        )}
        {orderStep === 4 && (
          <Button onClick={handleUpdateOrder} color="primary">
            Siguiente
          </Button>
        )}
        {orderStep === 5 && (
          <Button color="primary" onClick={handleCloseEditOrderModal}>
            Cerrar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderModal;
