import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import EnhancedTableHead from "./UsersTableHead";
import { IconButton, Tooltip } from "@material-ui/core";
import WhatsAppIcon from "../../icons/WhatsApp.png";
import EditIcon from "@material-ui/icons/Edit";

function createData(
  id,
  fullname,
  address,
  neighborhood,
  telephone,
  email,
  enabled
) {
  return { id, fullname, address, neighborhood, telephone, email, enabled };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  actionsCell:{
    minWidth: 100
  },
  container: {
    minHeight: "calc(100vh - 280px)",
    maxHeight: "calc(100vh - 280px)",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({ users, onUserSelected }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let mounted = true;
    const usersArr = [];

    users.map(
      ({ id, fullname, address, neighborhood, telephone, email, enabled }) =>
        usersArr.push(
          createData(
            id,
            fullname,
            address,
            neighborhood,
            telephone,
            email,
            enabled
          )
        )
    );

    if (mounted) {
      setRows(usersArr);
    }

    return () => {
      mounted = false;
    };
  }, [users]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleUserSelected = (userInfo) => {
    onUserSelected(userInfo);
  };

  return (
    rows.length > 0 && (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table className={classes.table} size={"medium"} stickyHeader>
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                        <TableCell
                          align="center"
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.fullname}
                        </TableCell>
                        <TableCell align="center">
                          {row.address || "- - -"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.neighborhood}
                        </TableCell>
                        <TableCell align="center">{row.telephone}</TableCell>
                        <TableCell>
                          <div className={classes.actionsCell}>
                            <Tooltip
                              placement="top"
                              title="Editar usuario"
                              arrow
                            >
                              <IconButton
                                onClick={() => handleUserSelected(row)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <a
                              href={`https://wa.me/57${row.telephone}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Tooltip
                                placement="top"
                                title="Enviar mensaje"
                                arrow
                              >
                                <IconButton>
                                  <img
                                    src={WhatsAppIcon}
                                    alt="Enviar WhatsApp"
                                    width="24px"
                                  />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    )
  );
}
