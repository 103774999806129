import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  title: {
    '& h2': {
      fontSize: '1rem',
    },
  },
  button: {
    '& span': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.865rem',
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function DrawerLogoutModal(props) {
  const { onClose, onLogout, open, ...other } = props;
  const classes = useStyles();

  const handleCancel = () => {
    onClose();
  };

  const handleLogout = () => {
    onLogout();
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      {...other}
    >
      <DialogTitle className={classes.title}>
        Desea cerrar la sesión?
      </DialogTitle>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={handleLogout}
          color="primary"
        >
          Cerrar Sesión
        </Button>
        <Button
          className={classes.button}
          onClick={handleCancel}
          color="primary"
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DrawerLogoutModal;
