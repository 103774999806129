import React from "react";
import clsx from "clsx";
import {
  Grid,
  TextField,
  Collapse,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { singularize } from "../../../helpers/singularize";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  defaultCursor: {
    cursor: "default",
  },
  pointerCursor: {
    cursor: "pointer",
  },
  productList: {
    maxWidth: 350,
  },
  productListItem: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: theme.spacing(4),
    position: "relative",
    "& svg": {
      position: "absolute",
      right: theme.spacing(1),
    },
  },
  textfield: {
    cursor: "default",
    marginBottom: theme.spacing(3),
    maxWidth: 270,
    "&:last-child": {
      marginBottom: theme.spacing(0),
    },
  },
}));

export const DisplayOrder = ({ orderInfo }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  console.log(orderInfo);

  const OrderFields = (label, value) => (
    <TextField
      className={classes.textfield}
      fullWidth
      label={label}
      multiline={label === "Comentarios"}
      rows={label === "Comentarios" ? 2 : 1}
      value={value}
      variant="outlined"
      InputProps={{
        readOnly: true,
      }}
    />
  );

  const ProductList = (cartArray) => {
    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <>
        <Typography align="center" variant="h4">
          Lista de Productos
        </Typography>
        <List className={classes.productList}>
          {cartArray.map(({ name, id, category, price, qty }, idx) =>
            qty > 1 ? (
              <>
                <ListItem
                  key={id}
                  divider
                  onClick={handleClick}
                  className={clsx(
                    classes.productListItem,
                    classes.pointerCursor
                  )}
                >
                  <span>{`${idx + 1}. ${name} ${singularize(category)}`}</span>
                  <span>{`$ ${(price * qty).toLocaleString("de-DE")}`}</span>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {`${qty} x ${price}`}
                </Collapse>
              </>
            ) : (
              <ListItem
                key={id}
                divider
                className={clsx(classes.productListItem, classes.defaultCursor)}
              >
                <span>{`${idx + 1}. ${name} ${singularize(category)}`}</span>
                <span>{`$ ${(price * qty).toLocaleString("de-DE")}`}</span>
              </ListItem>
            )
          )}
        </List>
      </>
    );
  };

  return (
    Object.keys(orderInfo).length > 0 && (
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={6}>
          {OrderFields("Nombre", orderInfo.fullname)}
          {OrderFields("Celular", orderInfo.telephone)}
          {OrderFields("Dirección", orderInfo.address)}
          {OrderFields("Barrio", orderInfo.neighborhood)}
          {OrderFields("Comentarios", orderInfo.comments)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {ProductList(orderInfo.cart)}
        </Grid>
      </Grid>
    )
  );
};
